import * as React from 'react';
import { ILinksData } from '../absolute-media-item.props.autogenerated';

export interface IContentCardLinks {
    links: ILinksData[];
}

const ContentCardLinks: React.FC<IContentCardLinks> = ({ links }) => (
    <div className='ms-content-block__cta'>
        {links.map((cta: ILinksData, index: number) => {
            const btnClass = index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary';
            return <a
                key={`link-${index}`}
                className={btnClass}
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                {cta.linkText}
            </a>;
        })}
    </div>
);

export default ContentCardLinks;