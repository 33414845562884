import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import { Button, Form, Label, Input } from "reactstrap";
import Swal from "sweetalert2";
// @ts-ignore
import DatePicker from "react-datepicker";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";


class RegistrationForm extends Component {
    state = {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        statepick: "",
        item: "",
        date: new Date(),
        product: "",
        message: "",
        recaptchaRef: "",
    };



    // @ts-ignore
    handleSubmit(e) {
        e.preventDefault();

        const {
            name,
            email,
            phone,
            address,
            city,
            statepick,
            zip,
            item,
            date,
            product,
            message,
            recaptchaRef,
        } = this.state;

        let templateParams = {
            from_name: name,
            from_email: email,
            phone: phone,
            address: address,
            city: city,
            statepck: statepick,
            zip: zip,
            item: item,
            date: date,
            to_name: "O'Brien Watersports",
            product: product,
            message: message,
            recaptchaRef: recaptchaRef,
        };

        emailjs.send(
            "obrien_365",
            "obrien_product_registrat",
            templateParams,
            "user_vOc0ylPHeC2nCdyLQJAiW"
        );

        this.resetForm();
    }
    resetForm() {
        this.setState({
            name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            statepick: "",
            zip: "",
            item: "",
            date: "",
            product: "",
            message: "",
            recaptchaRef: "",
        });
        Swal.fire({
            title: "Email Successfully Sent to the O'Brien Team",
            icon: "success",
        });
    }
    // @ts-ignore
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value });
    };

    // @ts-ignore
    dateChange = (date) => {
        this.setState({
            date: date,
        });
    };

    render() {
        return (
            <div id="contact-form">
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="flex-md">
                        <div className="fifty padding">
                            <Label className="text-muted">Full Name</Label>
                            <Input
                                type="text"
                                name="name"
                                value={this.state.name}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "name")}
                                placeholder="Name"
                            />
                        </div>
                        <div className="fifty padding">
                            <Label className="text-muted">Email address</Label>
                            <Input
                                type="email"
                                name="email"
                                value={this.state.email}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "email")}
                                placeholder="Enter email"
                            />
                        </div>
                    </div>
                    <div className="flex-md">
                        <div className="fifty padding">
                            <Label className="text-muted">Phone</Label>
                            <Input
                                type="tel"
                                name="phone"
                                value={this.state.phone}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "phone")}
                                placeholder="Enter Phone #"
                            />
                        </div>
                    </div>
                    <div className="padding">
                        <Label className="text-muted">Address</Label>
                        <Input
                            type="text"
                            name="address"
                            value={this.state.address}
                            className="text-primary"
                            onChange={this.handleChange.bind(this, "address")}
                            placeholder="Address"
                        />
                    </div>
                    <div className="flex-md">
                        <div className="thirty3 padding">
                            <Label className="text-muted">City</Label>
                            <Input
                                type="text"
                                name="city"
                                value={this.state.city}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "city")}
                                placeholder="City"
                            />
                        </div>
                        <div className="thirty3 padding">
                            <Label className="text-muted">State</Label>
                            <Input
                                type="select"
                                name="statepick"
                                value={this.state.statepick}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "statepick")}
                            >
                                <option>Select a State</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </Input>
                        </div>
                        <div className="thirty3 padding">
                            <Label className="text-muted">Zip Code</Label>
                            <Input
                                type="text"
                                name="zip"
                                value={this.state.zip}
                                className="text-primary"
                                onChange={this.handleChange.bind(this, "zip")}
                                placeholder="Zip"
                            />
                        </div>
                    </div>
                    <div className="padding">
                        <Label className="text-muted">Item Purchased</Label>
                        <Input
                            type="text"
                            name="item"
                            value={this.state.item}
                            className="text-primary"
                            onChange={this.handleChange.bind(this, "item")}
                            placeholder="item"
                        />
                    </div>
                    <div className="padding">
                        <Label className="text-muted">Date Purchased</Label>
                        <DatePicker
                        //@ts-ignore
                            type="text"
                            name="date"
                            selected={this.state.date}
                            className="text-primary"
                            onChange={this.dateChange}
                            placeholder="Date Purchased"
                        />
                    </div>
                    <div className="padding">
                        <Label className="text-muted">Message and additional notes</Label>
                        <Input
                            type="textarea"
                            name="message"
                            className="text-primary"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this, "message")}
                        />
                    </div>
                    <div className="padding">
                        <ReCAPTCHA
                            ref={this.state.recaptchaRef}
                            sitekey="6LcClc0ZAAAAAKoN2AsxwRRd4GMtD_yUG5AwXEhl"
                            onChange={this.handleChange.bind(this, "recaptchaRef")}
                        />
                    </div>
                    <div className="padding">
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}
export default RegistrationForm;
