import React from "react";
// @ts-ignore
import {GeneralAssetUrl} from './../../themes/kent/utilities/constants';
// @ts ignore
import animateScrollTo from 'animated-scroll-to';

interface IProps {
  items?:any;
  position?:any;
}

interface IState {
  scrollActive?:any;
  scrolling?:any;
  top?:any;
}

class AnchorNavigation extends React.Component<IProps> {
  public state: IState;

  constructor(props:any) {
    super(props);
      this.state = {
        top:true
      };
  }
  public componentDidMount = async() => {
    window.addEventListener("scroll",this.processScroll);
    this.processScroll();
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll",this.processScroll);
  }

  public processScroll = () => {
    if(window.pageYOffset < 80){
      this.setState({
        top:true,
        scrollActive:true,
        scrolling:false
      })
    } else{
      this.setState({
        scrolling:true,
        top:false,
        scrollActive:false
      })
    }
  }

  scrollDown = (anchor:any) =>{
    let className = "."+anchor;
    let element = document.querySelector(className);
    // @ts-ignore
    animateScrollTo(element,{verticalOffset:-95});
  }

  render() {
    let items = this.props.items;
    let position = this.props.position ? this.props.position : 'top';
    let active = !this.state.top ? 'active' : '';
    return (
      <div className={"anchor-navigation-wrapper primary-background shadow "+position+' '+active}>
        <div className="row anchor-navigation-menu">
          {items && items.map((data:any) =>{
            return(
              <div onClick={()=>this.scrollDown(data.anchor)}>
                <div className="anchor-navigation-link-item tiny-padding centered-text">
                  {data.title}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}
//@ts-ignore
export default AnchorNavigation;
