import React from "react";

interface IProps {
  backgroundImage?:string;
  title?:string;
  icon?:string;
}

class CountrySelector extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }
  saveLanguage = (lang:string) =>{
    if(lang == 'en'){
      localStorage.setItem("lang",'en')
      localStorage.setItem("country",'us')
    }
    if(lang == 'eu'){
			localStorage.setItem("lang",'eu')
      localStorage.setItem("country",'europe')
    }
    if(lang == 'de'){
			localStorage.setItem("lang",'de')
      localStorage.setItem("country",'germany')
    }
    if(lang == 'ca-fr'){
			localStorage.setItem("lang",'ca-fr')
      localStorage.setItem("country",'canada')
    }
		if(lang == 'ca-en'){
			localStorage.setItem("lang",'ca-en')
      localStorage.setItem("country",'canada')
    }
		if(lang == 'uk'){
			localStorage.setItem("lang",'uk')
      localStorage.setItem("country",'uk')
    }
    /*
    if(lang == 'ru'){
			localStorage.setItem("lang",'ru')
      localStorage.setItem("country",'russia')
    }
    */
		if(lang == 'au'){
			localStorage.setItem("lang",'au')
      localStorage.setItem("country",'australia')
    }
    window.location.assign('/')
  }
  render() {
    return (
      <div className="country-selector full-height full-width background-image-fill large-padding relative row" style={{height:"100vh", alignItems:"center",backgroundImage:'url('+this.props.backgroundImage+')'}}>
        <div className="max-width-container black-background small-padding white">
          <div className="centered-text">
            {this.props.icon && <img alt="" className="country-selector-icon max-width-container" style={{maxWidth:'100px'}} src={this.props.icon} />}
            {this.props.title && <h4 className="country-selector-title">{this.props.title}</h4>}
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('en')}}>{'US'}</div>
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('ca-en')}}>{'Canada English'}</div>
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('ca-fr')}}>{'Canada French'}</div>
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('de')}}>{'Germany'}</div>
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('uk')}}>{'United Kingdom'}</div>
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('eu')}}>{'Europe'}</div>
            {/* <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('ru')}}>{'Russia'}</div> */}
            <div style={{padding:"5px", cursor:"pointer"}} className="subheading country-selector-option" onClick={()=>{this.saveLanguage('au')}}>{'Australia'}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CountrySelector;
